
const ProjectPage = ({ id }) => {
    return (
        <div>
        <h1>Project Page</h1>
        <p>Project ID: {id}</p>
        </div>
    );
};

export default ProjectPage;
